<template>
  <div class="login-content">
    <div class="login">
      <v-form>
        <template>
          <img src="/assets/images/logo.png" width="100">
          <div>
            <v-text-field
                label="E-mail"
                type="email"
                outlined
                v-model="email"
                @keydown.enter="login"
            ></v-text-field>
            <v-text-field
                    outlined
                    label="Salasõna"
                    :type="passwordType"
                    :append-icon="show ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="togglePass"
                    @keydown.enter="login"
                    v-model="password">
            </v-text-field>
          </div>
        </template>
        <template>
          <v-btn
                  rounded
                  depressed
                  color="#213254"
                  dark
                  width="170px"
                  @click="login"
          >
            <strong>Logi sisse</strong>
          </v-btn>
        </template>
      </v-form>
      <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
              color="#dc6161"
      >
        Vale email või parool
        <template v-slot:action="{ attrs }">
          <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
          >
            Sulge
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>
<script>
    const axios = require('axios');
    export default {
        name: 'Login',
        data: () => ({
            email: '',
            password: '',
            rememberMe: false,
            snackbar: false,
            timeout: 3000,
            show: false,
            passwordType: 'password'
        }),

        mounted () {
          let self = this;
          let profileUrl = axios.defaults.baseURL + '/api/user';
          axios.get(profileUrl).then((response) => {
            self.$router.push('/offers')
          }).catch(function (error) {
            console.log(error);
          });
        },

        methods: {
          login: function () {
            let self = this;
            axios.get(axios.defaults.baseURL + '/sanctum/csrf-cookie')
                .then(response => {
                  axios.post(axios.defaults.baseURL + '/login',
                      {
                        email: this.email,
                        password: this.password
                      })
                      .then(response => {
                        // Quick hack for auth
                        setTimeout(() => {
                          if (response.status === 200) {
                            document.body.classList.add('profile-unloaded');
                            self.$router.push('/offers')
                          }
                        }, 500);
                      }).catch(function (error) {
                        if (error.response) {
                          self.snackbar = true;
                          console.log(error.response)
                        }
                      })
                })
          },
          togglePass () {
            this.show = !this.show;
            if (this.passwordType === 'text') {
              this.passwordType = 'password';
            } else {
              this.passwordType = 'text';
            }
          },
      }
    }
</script>
